import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
} from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getTabsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useLocation } from "react-router-dom";
import {
  serviceState,
  serviceType,
} from "../../../helpers/findParentCategoryId";
import { deleteTab } from "../../../api";
import ModalConfirmation from "../ui/modal";

const schema = createSchema({});
const values = {
  type: 0,
};

const TabsList = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useHookForm(values, schema);
  const QueryClient = useQueryClient();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteTabId, setDeleteTabId] = useState("");
  localStorage.setItem("path", location.pathname);

  function toggleModal(id) {
    setIsModalOpen(!isModalOpen);
    setDeleteTabId(id);
  }

  const { data: tabs, isFetched } = useQuery({
    ...getTabsQuery({
      ...watch(),
    }),
  });

  const columns = useMemo(
    () => [
      {
        Header: t("type"),
        accessor: "type",
        Cell: (tab) => {
          return <span>{serviceType(tab.row.original.type)}</span>;
        },
      },
      {
        Header: t("name"),
        accessor: "name",
      },
      {
        Header: t("order"),
        accessor: "order",
      },
      {
        Header: t("state"),
        accessor: "state",
        Cell: (tab) => {
          return <span>{serviceState(tab.row.original.state)}</span>;
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/tab/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to={`/service/edit/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-edit-line fs-4 p-1 fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <btn
                  className="ri-delete-bin-5-line fs-4 p-1"
                  onClick={() => {
                    toggleModal(cell.row.original.id);
                  }}
                  style={{ cursor: "pointer", color: "#FF4C4C" }}
                />
              </li>
              {cell.row.original.state === 1 && (
                <li className="list-inline-item">
                  <Link
                    to={`/tab/${cell.row.original.id}/add/products`}
                    className="text-primary d-flex align-align-items-center justify-content-between"
                  >
                    <i className="ri-add-line fs-4 p-1"></i>
                    <span style={{ display: "block", margin: "auto auto" }}>
                      {t("add_element")}
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          );
        },
      },
    ],
    []
  );
  document.title = "Tabs | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("Tabs")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <ModalConfirmation
                title={t("delete_confirmations")}
                isOpen={isModalOpen}
                toggleConfirmation={toggleModal}
              >
                {
                  <div className="d-flex justify-content-evenly">
                    <Button
                      color="success"
                      className="w-25"
                      onClick={() => toggleModal()}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      color="danger"
                      className="w-25"
                      onClick={() => {
                        deleteTab(deleteTabId).then(() => {
                          QueryClient.invalidateQueries("tabs");
                        });
                        toggleModal();
                      }}
                    >
                      {t("yes")}
                    </Button>
                  </div>
                }
              </ModalConfirmation>
              <CardHeader className="card-header border-0 pb-0">
                <Row>
                  <div className="d-flex justify-content-between align-items-center col-sm">
                    <h5 className="card-title mb-0">{t("Tabs")}</h5>
                    <Link
                      to={"/service/add"}
                      className="d-flex align-items-center"
                    >
                      <span>{t("add")}</span>
                      <btn
                        className="ri-add-line fs-4 p-1"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <>
                      <TableContainer
                        columns={columns}
                        data={tabs?.items || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TabsList;
