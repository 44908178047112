import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Accordion,
} from "reactstrap";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getCategoriesQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { AccordionCategory } from "../ui/accordion-category";

const schema = createSchema({});
const values = {};

const CategoriesList = () => {
  const { t } = useTranslation();
  const { watch } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getCategoriesQuery({
      ...watch(),
    }),
  });

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          const data = cell.row.original;
          return (
            <Accordion id="default-accordion-example">
              <AccordionCategory cell={cell} data={data} />
            </Accordion>
          );
        },
      },
    ],
    []
  );

  document.title = "Categories | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0 pb-0">
                <Row>
                  <div className="d-flex justify-content-between align-items-center col-sm">
                    <h5 className="card-title mb-0">{t("categories_list")}</h5>
                    <Link
                      to={"/categories/add"}
                      className="d-flex align-items-center"
                    >
                      <span>{t("add")}</span>
                      <btn
                        className="ri-add-line fs-4 p-1"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <TableContainer
                      columns={columns}
                      data={data || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      divClass="table-responsive table-card mb-1"
                      tableClass="table-borderless align-middle table-nowrap mb-0"
                      useSortBy={false}
                    />
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CategoriesList;
