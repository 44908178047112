import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
// import * as moment from "moment";
// import classnames from "classnames";
import debounce from "lodash/debounce";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getClientsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import { getClients } from "../../../api";

const schema = createSchema({});
const values = {
  pageIndex: 1,
  pageSize: 20,
  phoneNumber: "",
  sortBy: "id",
  desc: true,
};

const Clients = () => {
  const [allDataLoading, setAllDataLoading] = useState(false);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const { t } = useTranslation();

  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getClientsQuery({
      ...watch(),
    }),
  });

  // Fetch All Data for Excel export
  const getAllData = () => {
    setAllDataLoading(true);
    getClients({
      ...watch(),
      pageSize: data?.pagination?.TotalCount,
    })
      .then((res) => {
        setDataForDownload(res);
        setExportModal(true);
      })
      .finally(() => setAllDataLoading(false));
  };

  const debouncedSetValue = useMemo(
    () =>
      debounce((field, value) => {
        setValue(field, value);
      }, 500),
    [setValue]
  );

  const onSearchChange = (e) => {
    const { value } = e.target;
    debouncedSetValue("phoneNumber", value);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: t("client_full_name"),
        accessor: "firstName",
        filterable: false,
        Cell: (cell) => {
          const data = cell.row.original;
          return (
            <>
              <span>{data.lastName}</span>
              <span className="p-2 g-col-6">{data.firstName}</span>
              <span>{data.surname}</span>
            </>
          );
        },
      },
      {
        Header: t("phone"),
        accessor: "phoneNumber",
        filterable: false,
      },
      {
        Header: t("pinfl"),
        accessor: "pinfl",
        filterable: false,
      },
      {
        Header: t("state"),
        accessor: "state",
        filterable: false,
        Cell: (cell) => {
          return cell.value === 1 ? (
            <div className="text-success">{t("state1")}</div>
          ) : (
            <></>
          );
        },
      },

      {
        accessor: "Action",
        Cell: (client) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/client/${client.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Clients | Taqsim";

  return (
    <div className="page-content">
      <ExportCSVModal
        show={exportModal}
        onCloseClick={() => setExportModal(false)}
        data={dataForDownload}
        dataName={"clients"}
      />
      <Container fluid>
        <BreadCrumb title={t("clients")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("clients")}</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <Button
                        type="button"
                        className="btn"
                        color="primary"
                        onClick={getAllData}
                        disabled={allDataLoading}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {t(allDataLoading ? "downloading" : "export")}
                      </Button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0 pt-4">
                    <form>
                      <Row>
                        <Col sm={5}>
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <input
                              id="search-bar-0"
                              type="text"
                              className="form-control search /"
                              placeholder={t("phone_number")}
                              defaultValue={watch("phoneNumber")}
                              onChange={onSearchChange}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: watch("pageIndex"),
                          totalPages: data?.pagination?.TotalPages,
                          onChange: (page) => setValue("pageIndex", page),
                        }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={(e) => {
                          const newSize = parseInt(e.target.value);
                          setValue("size", newSize);
                          setValue("page", 1);
                        }}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Clients;
