import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Collapse,
  Label,
  Badge,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery } from "@tanstack/react-query";
import { getOrderQuery, getProductCardQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import Textfield from "../ui/text-field";
import * as moment from "moment";
import Rating from "react-rating";
import { prettify } from "../../../helpers/price-formater";
import { CheckStatus } from "../../../helpers/findParentCategoryId";

const OrderView = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();

  const { data: order } = useQuery({
    ...getOrderQuery(orderId),
  });

  const { data: productCard } = useQuery({
    ...getProductCardQuery(order?.productId),
    enabled: order?.productId !== undefined,
  });

  document.title = "Order Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("order_details")} pageTitle={t("home")} />
        {order?.id && (
          <Row>
            <Col xl={8}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5 className="card-title flex-grow-1 mb-0 fs-4 fw-bold">
                      {t("order_number")}
                      {order.id}
                      <Label className="opacity-50 fs-6">
                        {` от ${moment(order.createdDate).format(
                          "DD.MM.YYYY"
                        )}`}
                      </Label>
                    </h5>
                    <div className="flex-shrink-0">
                      {CheckStatus(order.status, t)}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="col-md-10">
                    <Label className="fw-bold fs-4 my-3">
                      {t("about_organization")}
                    </Label>
                    <Textfield
                      name={"organization_name"}
                      source={order.organization}
                    />
                  </div>
                  <div className="col-md-10">
                    <Label className="fw-bold fs-4 my-3">{t("all_info")}</Label>
                    <Textfield name={"phone_number"} source={order.phone} />
                    <Textfield name={"full_name"} source={order.fullname} />
                    <Textfield
                      name={"dealType"} 
                      source={
                        order.isInstallment
                          ? t("with_installment")
                          : t("with_compensation")
                      }
                    />
                    <Textfield name={"build_address"} source={order.address} />
                    <Textfield name={"Comments"} source={order.comment} />
                  </div>
                  {order.buildDetails && (
                    <div className="col-md-10">
                      <Label className="fw-bold fs-4 my-3">
                        {t("place_installing")}
                      </Label>
                      <Textfield
                        name={"area_in_sqm"}
                        source={order.buildDetails.Area_in_sqm}
                      />
                      <Textfield
                        name={"choose_roof_type"}
                        source={order.buildDetails.Choose_roof_type}
                      />
                      <Textfield
                        name={"choose_roof_type2"}
                        source={order.buildDetails.Choose_roof_type2}
                      />
                      <Textfield
                        name={"type_building"}
                        source={order.buildDetails.Choose_building_type}
                      />
                      <Textfield
                        name={"enter_usable_roof_area_sqm"}
                        source={order.buildDetails.Enter_usable_roof_area_sqm}
                      />
                      <Textfield
                        name={"select_installation_location"}
                        source={order.buildDetails.Select_installation_location}
                      />
                      <Textfield
                        name={"choose_type_of_precrimination"}
                        source={
                          order.buildDetails.Choose_type_of_precrimination
                        }
                      />
                      <Textfield
                        name={"choose_a_load_bearing_wall_material"}
                        source={
                          order.buildDetails.Choose_a_load_bearing_wall_material
                        }
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            {productCard?.prices?.find((item) => item.type === "PayAmount") && (
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <div className="col-md-12">
                      <img
                        src={productCard?.files?.map((item) => item.url)}
                        className="img-fluid"
                        alt="Responsive"
                      />

                      <Rating
                        initialRating={5}
                        emptySymbol="mdi mdi-star-outline text-muted "
                        fullSymbol="mdi mdi-star text-warning "
                        className="fs-4"
                      />
                    </div>
                    <Label className="fs-4 d-block">
                      {productCard?.product?.name}
                    </Label>
                    <Label className="fs-5" style={{ color: "#FF8F00" }}>
                      {prettify(
                        productCard?.prices
                          ?.filter((item) => item.type === "Price")
                          .map((item) => item.value)
                      )}
                    </Label>
                    <Label className="opacity-50 fs-5">{t("pay_amount")}</Label>
                    <Label className="fs-5">
                      {prettify(
                        productCard?.prices
                          ?.filter((item) => item.type === "PayAmount")
                          .map((item) => item.value)
                      )}
                    </Label>
                    <Label className="opacity-50 fs-5 d-block">
                      {t("price_installment")}
                    </Label>
                    <div className="d-flex align-items-start">
                      {productCard?.compensationOnly ? (
                        <>{"Не доступно"}</>
                      ) : (
                        <>
                          <Label className="fs-5">
                            {prettify(
                              productCard?.prices
                                ?.filter((item) => item.type === "Installment")
                                .map((item) => item.value / 36)
                            )}
                          </Label>
                          <Badge color="warning" className="fs-7">
                            {"x 36"}{t("month")}
                          </Badge>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default OrderView;
