import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getFiltersQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import { deleteFilters } from "../../../api";

const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
  sortBy: "id",
  desc: true,
};

const FiltersList = () => {
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const { watch, setValue } = useHookForm(values, schema);

  const { data, isFetched } = useQuery({
    ...getFiltersQuery({
      ...watch(),
    }),
  });

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: t("name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: t("weight"),
        accessor: "weight",
        filterable: false,
      },
      {
        Header: t("filter_type"),
        accessor: "filterType",
        filterable: false,
      },
      {
        Header: t("data_type"),
        accessor: "dataType",
        filterable: false,
      },
      // {
      //   Header: t("Action"),
      //   Cell: (cell) => {
      //     return (
      //       <btn
      //         className="ri-delete-bin-5-line fs-4 p-1"
      //         onClick={() => deleteFilters(cell.row.original.id).then(() => {
      //           QueryClient.invalidateQueries("filters")
      //         })}
      //         style={{ cursor: "pointer" }}
      //       />
      //     );
      //   },
      // },
    ],
    []
  );

  document.title = "Filters | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("filters")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="d-flex justify-content-between align-items-center col-sm">
                    <h5 className="card-title mb-0">{t("filters")}</h5>
                    <Link
                      to={"/filters/add"}
                      className="d-flex align-items-center"
                    >
                      <span>{t("add")}</span>
                      <btn
                        className="ri-add-line fs-4 p-1"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: data?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={(e) => {
                          const newSize = parseInt(e.target.value);
                          setValue("size", newSize);
                          setValue("page", 1);
                        }}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FiltersList;
