import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Button,
  Input as TextInput,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getProductsQuery, getTabQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import {
  serviceState,
  serviceType,
} from "../../../helpers/findParentCategoryId";
import { Input } from "../../../Components/atoms/input";
import useHookForm from "../../../hooks/useHookForm";
// import { deleteTabItem } from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import { prettify } from "../../../helpers/price-formater";

const schema = {};
const values = {};

const TabDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);
  const QueryClient = useQueryClient();

  const { data: tab } = useQuery({
    ...getTabQuery(id),
  });
  const { data: products, isFetched } = useQuery({
    ...getProductsQuery({
      page: 1,
      size: 100,
      moderationStatus: 1,
      tab: parseInt(id),
    }),
  });

  useEffect(() => {
    if (tab) {
      tab.names.map((item) => setValue(`name${item.languageCode}`, item.text));
      setValue("state", serviceState(tab.state));
      setValue("type", serviceType(tab.type));
      setValue("order", tab.order);
    }
  }, [tab]);

  document.title = "Service Details | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {t("about_tab")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody className="d-flex justify-content-between flex-wrap gap-4">
                <Row className="d-flex flex-column gap-2 w-50 col-5">
                  <Input
                    control={control}
                    errors={errors}
                    name="type"
                    label="tab_type"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="state"
                    label="tab_state"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="order"
                    label="order"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                </Row>
                <Row className="d-flex flex-column gap-2 w-50 col-5">
                  <Input
                    control={control}
                    errors={errors}
                    name="nameru"
                    label="name_ru"
                    inputProps={{
                      disabled: true,
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameuz-Cyrl-UZ"
                    label="name_uz-Cyrl-UZ"
                    inputProps={{
                      disabled: true,
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameuz-Latn-UZ"
                    label="name_uz-Latn-UZ"
                    inputProps={{
                      disabled: true,
                    }}
                  />
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                {products?.length > 0 && <Label>{t("products")}</Label>}
              </CardHeader>
              <CardBody>
                <Row className="d-flex justify-content-between gap-3 w-100">
                  {products?.map(
                    (item) =>
                      item.variations !== null && (
                        <div
                          className="border form-control col-4 d-flex flex-column gap-1"
                          style={{ marginLeft: "0.8rem", width: "45%" }}
                          id="img2"
                          key={item.id}
                        >
                          <Label>{t("product_name")}</Label>
                          <TextInput value={item.name} disabled />

                          <Label>{t("product_number")}</Label>
                          <TextInput value={item.productNumber} disabled />

                          <Label>{t("origin_price")}</Label>
                          <TextInput
                            value={prettify(item.variations[0].originPrice)}
                            disabled
                          />
                        </div>
                      )
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TabDetail;
