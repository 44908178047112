import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as moment from "moment";
import { Card, CardBody, Col, Container, Row, Input, Button } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getReviewsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import Rating from "react-rating";
import { useCopyToClipboard } from "usehooks-ts";
import { toast, ToastContainer } from "react-toastify";

const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
  type: 0,
  status: "",
};

const FeedBackReviews = () => {
  const { t } = useTranslation();
  const { watch, setValue } = useHookForm(values, schema);
  const [_, copy] = useCopyToClipboard();

  const { data: reviews, isFetched } = useQuery({
    ...getReviewsQuery({
      type: 0,
      ...watch(),
    }),
  });

  const columns = useMemo(
    () => [
      {
        Header: t("created_date"),
        accessor: t("date"),
        Cell: (review) => (
          <>{moment(review.row.original.date).format("DD.MM.YYYY")}</>
        ),
      },
      {
        Header: t("content_reviews"),
        accessor: "content",
        Cell: (review) => (
          <div style={{ width: "10rem" }}>
            <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              {review.row.original.content}
            </p>
            <Rating
              initialRating={review.row.original.rating}
              emptySymbol="mdi mdi-star-outline text-muted "
              fullSymbol="mdi mdi-star text-warning "
              className="fs-4"
              readonly
            />
          </div>
        ),
      },
      {
        Header: t("user_reviews"),
        accessor: "user",
        Cell: (review) => (
          <>
            <p>{review.row.original.user?.firstname}</p>
            <p>{review.row.original.user?.lastname}</p>
          </>
        ),
      },
      {
        Header: t("product_reviews"),
        accessor: "product",
        Cell: (review) => (
          <div style={{ width: "15rem" }}>
            <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              {review.row.original.for?.name}
            </p>
            <Button
              color="primary"
              outline
              size="sm"
              className="ri-information-line"
              onClick={() => {
                copy(review.row.original.for?.id);
                toast("id товара скопирована", {
                  type: "info",
                  theme: "dark",
                  position: "bottom-right",
                  autoClose: 800,
                });
              }}
            />
          </div>
        ),
      },
      {
        Header: t("reviews_feedback"),
        accessor: "reply",
        Cell: (review) => (
          <div style={{ width: "15rem" }}>
            <p
              style={{
                wordBreak: "break-word",
                whiteSpace: "normal",
                fontWeight: 600,
                color: "#3577f1",
              }}
            >
              {review.row.original.reply.organization.name}
            </p>
            <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              {review.row.original.reply.content}
            </p>
          </div>
        ),
      },
    ],
    []
  );

  document.title = "Orders | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title={t("reviews_feedback")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: reviews?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                        columns={columns}
                        data={
                          reviews?.items.filter(
                            (item) => item.reply !== null
                          ) || []
                        }
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={(e) => {
                          const newSize = parseInt(e.target.value);
                          setValue("size", newSize);
                          setValue("page", 1);
                        }}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FeedBackReviews;
