import flagrussia from "../assets/images/flags/russia.svg";
import flaguzbekistan from "../assets/images/flags/uz.svg";


const languages = {
  rs: {
    label: "русский",
    flag: flagrussia,
  },
  uz: {
    label: "узбекский",
    flag: flaguzbekistan,
  },
}

export default languages
