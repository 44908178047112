import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Label,
  Input as InputFile,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { createCategories, uploadPhoto, editCategories } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getCategoryQuery } from "../../../queries";
import Dropzone from "react-dropzone";
import RadioButtonInput from "../ui/radio-button";

const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  imageId: "",
  isVisible: "",
};

const CategoriesCreate = () => {
  const [loading, setLoading] = useState(false);
  const [filePreviews, setFilePreviews] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, parentId } = useParams();
  const numbId = parseInt(id);
  const parent = parseInt(parentId);
  const QueryClient = useQueryClient();
  const schema = createSchema({
    nameru: "name",
    "nameuz-Latn-UZ": "name",
    "nameuz-Cyrl-UZ": "name",
    files: "",
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setError,
  } = useHookForm(values, schema);

  const { data: category } = useQuery({
    ...getCategoryQuery(id),
    enabled: id !== undefined,
  });

  const handleFileUpload = async (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
    }));
    setFilePreviews(...newFiles.map((f) => f.preview));
    setValue("files", acceptedFiles[0]);
  };

  const onSubmit = handleSubmit((res) => {
    if (!watch("files")) {
      return setError("files", {
        type: "manual",
        message: t("required"),
      });
    }
    if (res) {
      setValue("nameru", res.nameru);
      setValue("nameuz-Latn-UZ", res["nameuz-Latn-UZ"]);
      setValue("nameuz-Cyrl-UZ", res["nameuz-Cyrl-UZ"]);

      const names = Object.keys(res.name).map((languageCode) => ({
        languageCode,
        text: watch(`name${languageCode}`),
      }));
      setLoading(true);

      const formData = new FormData();
      formData.append("files", watch("files"));

      uploadPhoto(formData).then((res) => {
        setLoading(false);
        const datas = id
          ? {
              id: numbId,
              name: names,
              imageId: res[0].id,
              isVisible,
            }
          : parentId
          ? {
              parentId: parent,
              name: names,
              imageId: res[0].id,
              isVisible,
            }
          : {
              name: names,
              imageId: res[0].id,
              isVisible,
            };
        id
          ? editCategories(datas)
              .then((res) => {
                setLoading(false);
                QueryClient.invalidateQueries(["categories"]);
                navigate("/categories");
              })
              .catch((err) => alert(err.data?.error))
          : createCategories(datas)
              .then((res) => {
                setLoading(false);
                QueryClient.invalidateQueries(["categories"]);
                navigate("/categories");
              })
              .catch((err) => alert(err.data?.error));
      });
    }
  });

  useEffect(() => {
    if (category?.name?.length >= 3) {
      category.name.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      const previews = category?.image?.url;
      setFilePreviews(previews);
      const file = new File([], category.image.url, {
        type: "image/jpeg",
      });
      setValue("files", file);
      setIsVisible(category.isVisible);
    }
  }, [category]);

  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {id ? t("edit_category") : t("add_category")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <Input
                    name="nameru"
                    control={control}
                    errors={errors}
                    label="name_ru"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    name="nameuz-Cyrl-UZ"
                    control={control}
                    errors={errors}
                    label="name_uz-Cyrl-UZ"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    name="nameuz-Latn-UZ"
                    control={control}
                    errors={errors}
                    label="name_uz-Latn-UZ"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <div className="d-flex gap-2 flex-wrap mt-3">
                    <div style={{ width: "300px", marginBottom: "20px" }}>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleFileUpload(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps({ className: "dropzone" })}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              height: "80px",
                              border: "2px dashed #cccccc",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {!filePreviews && (
                              <div className="d-flex align-items-center justify-content-center">
                                <i className="bx bx-image-add display-4"></i>
                              </div>
                            )}
                            {filePreviews && (
                              <img
                                src={filePreviews}
                                alt={`Img`}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  {errors.files && (
                    <span className="text-danger">{errors.files.message}</span>
                  )}
                  <RadioButtonInput
                    label={"isVisible"}
                    state={isVisible}
                    setState={setIsVisible}
                    id={1}
                  />

                  <Button
                    color="success"
                    className="btn btn-success w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CategoriesCreate;
