import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Button,
  Input as TextInput,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getTabQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import {
  serviceState,
  serviceType,
} from "../../../helpers/findParentCategoryId";
import { Input } from "../../../Components/atoms/input";
import useHookForm from "../../../hooks/useHookForm";
import { deleteTabItem } from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import ModalConfirmation from "../ui/modal";

const schema = {};
const values = {};

const ServceDetail = () => {
  document.title = "Service Details | Taqsim";
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);
  const QueryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");

  function toggleModal(id) {
    setIsModalOpen(!isModalOpen);
    setDeleteItem(id);
  }

  const { data: tab } = useQuery({
    ...getTabQuery(id),
  });

  useEffect(() => {
    if (tab) {
      tab.names.map((item) => setValue(`name${item.languageCode}`, item.text));
      setValue("state", serviceState(tab.state));
      setValue("type", serviceType(tab.type));
      setValue("order", tab.order);
    }
  }, [tab]);
  console.log(serviceType(tab?.type));
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col xl={12}>
            <Card>
              <ModalConfirmation
                title={t("delete_confirmations")}
                isOpen={isModalOpen}
                toggleConfirmation={toggleModal}
              >
                {
                  <div className="d-flex justify-content-evenly">
                    <Button
                      color="success"
                      className="w-25"
                      onClick={() => toggleModal()}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      color="danger"
                      className="w-25"
                      onClick={() => {
                        deleteTabItem(id, deleteItem)
                          .then(() => {
                            toast("Элемент удален", {
                              type: "error",
                              theme: "colored",
                              position: "top-center",
                              autoClose: "2000",
                            });
                            QueryClient.invalidateQueries(["tab"]);
                          })
                          .catch((error) => console.log(error));
                        toggleModal();
                      }}
                    >
                      {t("yes")}
                    </Button>
                  </div>
                }
              </ModalConfirmation>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {"Информация о сервисе"}
                  </h5>
                </div>
              </CardHeader>
              <CardBody className="d-flex justify-content-between flex-wrap gap-4">
                <Row className="d-flex flex-column gap-2 w-50 col-5">
                  <Input
                    control={control}
                    errors={errors}
                    name="type"
                    label="tab_type"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="state"
                    label="tab_state"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="order"
                    label="order"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                </Row>
                <Row className="d-flex flex-column gap-2 w-50 col-5">
                  <Input
                    control={control}
                    errors={errors}
                    name="nameru"
                    label="name_ru"
                    inputProps={{
                      disabled: true,
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameuz-Cyrl-UZ"
                    label="name_uz-Cyrl-UZ"
                    inputProps={{
                      disabled: true,
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameuz-Latn-UZ"
                    label="name_uz-Latn-UZ"
                    inputProps={{
                      disabled: true,
                    }}
                  />
                </Row>
              </CardBody>
            </Card>
            {tab?.items.length > 0 && (
              <Card>
                <CardBody>
                  <Row className="d-flex justify-content-between gap-3 w-100">
                    <Label>{t("elements")}</Label>
                    {tab?.items?.map((item) => (
                      <div
                        className="border form-control col-4 d-flex flex-column gap-1"
                        style={{ marginLeft: "0.8rem", width: "45%" }}
                        id="img2"
                        key={item.id}
                      >
                        <Label>{t("file_id")}</Label>
                        <TextInput value={item.fileId} disabled />

                        <Label>{t("link")}</Label>
                        <TextInput value={item.link} disabled />

                        <Label>{t("order")}</Label>
                        <TextInput value={item.order} disabled />

                        <div className="d-flex align-items-end justify-content-between mt-3">
                          <Button
                            color="danger"
                            style={{ width: "150px" }}
                            onClick={() => {
                              toggleModal(item.id);
                            }}
                          >
                            <span>{t("delete_element")}</span>
                          </Button>
                          <Button
                            color="success"
                            style={{ width: "150px" }}
                            onClick={() =>
                              navigate(`/service/${id}/item/${item.id}/edit`)
                            }
                          >
                            <span>{t("edit_element")}</span>
                          </Button>
                        </div>
                      </div>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServceDetail;
