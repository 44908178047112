import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Label,
} from "reactstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { addService, editService } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { getTabQuery } from "../../../queries";
import {
  serviceState,
  serviceType,
} from "../../../helpers/findParentCategoryId";

const schema = createSchema({
  nameru: "name",
  "nameuz-Latn-UZ": "name",
  "nameuz-Cyrl-UZ": "name",
  type: "select",
  state: "select",
  order: "numberOrder",
});
const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  type: "",
  state: "",
  order: "",
};

const ServiceAdd = () => {
  const navigate = useNavigate();
  const QueryClient = useQueryClient();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { data: tab } = useQuery({
    ...getTabQuery(id),
    enabled: id !== undefined,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useHookForm(values, schema);

  const onSubmit = handleSubmit((res) => {
    setValue("nameru", res.nameru);
    setValue("nameuz-Latn-UZ", res["nameuz-Latn-UZ"]);
    setValue("nameuz-Cyrl-UZ", res["nameuz-Cyrl-UZ"]);

    const names = Object.keys(res.name).map((languageCode) => ({
      languageCode,
      text: watch(`name${languageCode}`),
    }));

    setLoading(true);

    const datas = {
      names,
      order: parseInt(res.order),
      type: parseInt(res.type.value),
      state: parseInt(res.state.value),
      ...(id && { id: parseInt(id) }),
    };

    id
      ? editService(id, datas)
          .then(() => {
            setLoading(false);
            navigate(localStorage.getItem("path"));
            QueryClient.invalidateQueries(["tabs"]);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          })
      : addService(datas)
          .then(() => {
            setLoading(false);
            navigate(localStorage.getItem("path"));
            QueryClient.invalidateQueries(["tabs"]);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
  });

  useEffect(() => {
    if (tab) {
      tab.names.map((item) => setValue(`name${item.languageCode}`, item.text));
      setValue("type", {
        value: tab.type,
        label: serviceType(tab.type),
      });
      setValue("state", {
        value: tab.state,
        label: serviceState(tab.state),
      });
      setValue("order", tab.order);
    }
  }, [tab]);

  document.title = "Service Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {t("add_service")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <Label>{t("service_type")}</Label>
                  <Controller
                    name="type"
                    control={control}
                    className="bg-white"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { value: 0, label: t("tab") },
                          { value: 1, label: t("service") },
                          { value: 2, label: t("banner") },
                        ]}
                        placeholder={t("select")}
                      />
                    )}
                  />
                  {errors.type && (
                    <span
                      style={{ fontSize: "0.71rem" }}
                      className="text-danger"
                    >
                      {t(errors.type.message)}
                    </span>
                  )}
                  <Label>{t("service_state")}</Label>
                  <Controller
                    name="state"
                    control={control}
                    className="bg-white"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { value: 0, label: t("created") },
                          { value: 1, label: t("approved") },
                          { value: 2, label: 2 },
                          { value: 3, label: t("canceled") },
                          { value: 9, label: t("deleted") },
                        ]}
                        placeholder={t("select")}
                      />
                    )}
                  />
                  {errors.state && (
                    <span
                      style={{ fontSize: "0.71rem" }}
                      className="text-danger"
                    >
                      {t(errors.state.message)}
                    </span>
                  )}
                  <Input
                    control={control}
                    errors={errors}
                    name="order"
                    label="order"
                    isInteger
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameru"
                    label="name_ru"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameuz-Cyrl-UZ"
                    label="name_uz-Cyrl-UZ"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameuz-Latn-UZ"
                    label="name_uz-Latn-UZ"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Button
                    color="success"
                    className="btn btn-
                     w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceAdd;
