import {
  getContracts,
  getOrders,
  getOrder,
  getOrganizations,
  getClients,
  getProducts,
  getModerations,
  getCategories,
  getFilters,
  getAttributes,
  getProductsCard,
  getClient,
  getProduct,
  getModeration,
  getOrganization,
  getOrgFormalType,
  getRegions,
  getCategory,
  getAttribute,
  getReviews,
  getTabs,
  getTab,
  getReserve,
  getReserveById,
} from "../api/index";

export function getOrdersQuery(params) {
  return {
    queryKey: ["orders", params],
    queryFn: async () => getOrders(params),
  };
}

export function getOrderQuery(id) {
  return {
    queryKey: ["order", id],
    queryFn: async () => getOrder(id),
  };
}

export function getReserveQuery(params) {
  return {
    queryKey: ["reserve", params],
    queryFn: async () => getReserve(params),
  };
}

export function getReserveByIdQuery(id) {
  return {
    queryKey: ["reserve-id", id],
    queryFn: async () => getReserveById(id),
  };
}

export function getProductCardQuery(id) {
  return {
    queryKey: ["product-card", id],
    queryFn: async () => getProductsCard(id),
  };
}

export function getContractsQuery(params) {
  return {
    queryKey: ["contracts", params],
    queryFn: async () => getContracts(params),
  };
}

export function getOrganizationsQuery(params) {
  return {
    queryKey: ["user-organizations", params],
    queryFn: async () => getOrganizations(params),
  };
}

export function getOrganizationQuery(id) {
  return {
    queryKey: ["user-organizations", id],
    queryFn: async () => getOrganization(id),
  };
}

export function getClientsQuery(params) {
  return {
    queryKey: ["user-clients", params],
    queryFn: async () => getClients(params),
  };
}

export function getClientQuery(id) {
  return {
    queryKey: ["client", id],
    queryFn: async () => getClient(id),
  };
}

export function getProductsQuery(params) {
  return {
    queryKey: ["products", params],
    queryFn: async () => getProducts(params),
  };
}

export function getModerationQuery(moderId) {
  return {
    queryKey: ["moderation", moderId],
    queryFn: async () => getModeration(moderId),
  };
}

export function getCategoriesQuery(params) {
  return {
    queryKey: ["categories", params],
    queryFn: async () => getCategories(params),
  };
}
export function getCategoryQuery(id) {
  return {
    queryKey: ["categories", id],
    queryFn: async () => getCategory(id),
  };
}

export function getFiltersQuery(params) {
  return {
    queryKey: ["filters", params],
    queryFn: async () => getFilters(params),
  };
}
export function getAttributesQuery(params) {
  return {
    queryKey: ["attributes", params],
    queryFn: async () => getAttributes(params),
  };
}
export function getAttributeQuery(id) {
  return {
    queryKey: ["attribute", id],
    queryFn: async () => getAttribute(id),
  };
}

export function getProductQuery(id) {
  return {
    queryKey: ["product", id],
    queryFn: async () => getProduct(id),
  };
}

export function getFormalTypeQuery() {
  return {
    queryKey: ["formalType"],
    queryFn: async () => getOrgFormalType(),
  };
}

export function getRegionsQuery() {
  return {
    queryKey: ["regions"],
    queryFn: async () => getRegions(),
  };
}
export function getReviewsQuery(params) {
  return {
    queryKey: ["reviews", params],
    queryFn: async () => getReviews(params),
  };
}
export function getTabsQuery(params) {
  return {
    queryKey: ["tabs", params],
    queryFn: async () => getTabs(params),
  };
}
export function getTabQuery(id) {
  return {
    queryKey: ["tab", id],
    queryFn: async () => getTab(id),
  };
}
