import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Form,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery } from "@tanstack/react-query";
import { getClientQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import useHookForm from "../../../hooks/useHookForm";
import { Input } from "../../../Components/atoms/input";
import { DateInput } from "../../../Components/atoms/date-input";

const values = {};
const schema = {};

const ClientView = () => {
  const { clientId } = useParams();
  const { t } = useTranslation();
  const lang = localStorage.getItem("I18N_LANGUAGE");

  const { data: client } = useQuery({
    ...getClientQuery(clientId),
  });

  const {
    control,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);

  useEffect(() => {
    if (client) {
      setValue("phoneNumber", client.phoneNumber);
      setValue("document_type", t("passport"));
      setValue(
        "passport_info",
        `${client.bioPassportSerial || ""} ${client.bioPassportNumber || ""}`
      );
      setValue("firstName", `${client.firstName || ""}`);
      setValue("lastName", `${client.lastName || ""}`);
      setValue("surName", `${client.surName || ""}`);
      setValue("birthDate", `${client.birthDate}`);
      setValue("address", `${client.address || ""}`);
      setValue("pinfl", `${client.pinfl || ""}`);
      setValue("tin", `${client.tin || ""}`);
      setValue("ball", `${client.scoring?.ball || ""}`);
      setValue("enableSumma", `${client.scoring?.enableSumma || ""}`);
      setValue("comment", `${client.scoring?.comment || ""}`);
      setValue("reason", `${client.scoring?.reason || ""}`);
    }
  }, [client, lang]);

  document.title = "Client Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("client_details")} pageTitle={t("home")} />
        {client?.id && (
          <Row>
            <Col xl={9}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5
                      className="card-title flex-grow-1 mb-0"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      {t("client_data")}
                    </h5>
                    <div className="flex-shrink-0">
                      {client.state === 1 ? (
                        <div className="text-success">{t("state1")}</div>
                      ) : (
                        <div className="text-danger">{t("state2")}</div>
                      )}
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="d-flex flex-column align-item-center gap-3">
                  <Form action="#">
                    <Col xl={10} className="mb-3">
                      <Input
                        name="phoneNumber"
                        control={control}
                        errors={errors}
                        label="phone"
                        inputProps={{
                          disabled: true,
                        }}
                      />
                    </Col>
                    <Row>
                      <Col xl={5} className="d-flex flex-column gap-3">
                        <Input
                          name="lastName"
                          control={control}
                          errors={errors}
                          label="lastName"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                        <Input
                          name="firstName"
                          control={control}
                          errors={errors}
                          label="firstName"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                        <Input
                          name="surName"
                          control={control}
                          errors={errors}
                          label="surName"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                        <Input
                          name="tin"
                          control={control}
                          errors={errors}
                          label="tin"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      </Col>
                      <Col xl={5} className="d-flex flex-column gap-3">
                        <Input
                          name="document_type"
                          control={control}
                          errors={errors}
                          label="document_type"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                        <Input
                          name="passport_info"
                          control={control}
                          errors={errors}
                          label="passport_info"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                        <DateInput
                          name="birthDate"
                          control={control}
                          errors={errors}
                          label="birth_date"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                        <Input
                          name="pinfl"
                          control={control}
                          errors={errors}
                          label="pinfl"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      </Col>
                    </Row>
                    <Col xl={10} className="mb-3">
                      <Input
                        name="address"
                        control={control}
                        errors={errors}
                        label="address"
                        inputProps={{
                          disabled: true,
                        }}
                      />
                    </Col>
                  </Form>
                </CardBody>
              </Card>
              {client?.scoring && (
                <Card>
                  <CardHeader>
                    <Label>{t("preliminary_scoring")}</Label>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xl={5}>
                        <Input
                          name="ball"
                          control={control}
                          errors={errors}
                          label="ball"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      </Col>
                      <Col xl={5}>
                        <Input
                          name="enableSumma"
                          control={control}
                          errors={errors}
                          label="enableSumma"
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      </Col>
                    </Row>
                    <Col xl={10} className="mt-3 d-flex flex-column gap-3">
                      <Input
                        name="comment"
                        control={control}
                        errors={errors}
                        label="comment"
                        inputProps={{
                          disabled: true,
                        }}
                      />
                      <Input
                        name="reason"
                        control={control}
                        errors={errors}
                        label="reason"
                        inputProps={{
                          disabled: true,
                        }}
                      />
                    </Col>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ClientView;
