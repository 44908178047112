import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const ModalConfirmation = ({ isOpen, toggleConfirmation, children, title }) => (
  <Modal
    id="flipModal"
    isOpen={isOpen}
    toggle={() => {
      toggleConfirmation();
    }}
    modalClassName="zoomIn"
    centered
  >
    <ModalHeader
      className="modal-title"
      id="flipModalLabel"
      toggle={() => {
        toggleConfirmation();
      }}
    >
      {title}
    </ModalHeader>
    <ModalBody>
      {children}
      {/* <div className="modal-footer">
        <Button
          color="light"
          onClick={() => {
            toggleConfirmation();
          }}
        >
          {"Close"}
        </Button>
      </div> */}
    </ModalBody>
  </Modal>
);

export default ModalConfirmation;
