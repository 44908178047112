import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Input as TextInput,
  Label,
} from "reactstrap";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery } from "@tanstack/react-query";
import { getProductQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Components/atoms/input";
import useHookForm from "../../../hooks/useHookForm";

const values = {};
const schema = {};

const ProductView = () => {
  const { productId } = useParams();
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);

  const { data: product } = useQuery({
    ...getProductQuery(productId),
  });
  console.log(product);
  useEffect(() => {
    if (product) {
      setValue("categoryName", product.category.name);
      product.names.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      product.descriptions.map((item) =>
        setValue(`description${item.languageCode}`, item.text)
      );
      product.variations?.[0].prices.map((item) =>
        setValue(`price_${item.type}`, item.value)
      );
      product.variations?.[0].attributeValues.map((item) =>
        setValue(`value_${item.attribute?.name}`, item.value)
      );
    }
  }, [product]);

  document.title = "Product Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        {product?.id && (
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center">
                    <h5
                      className="card-title flex-grow-1 mb-0"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      {t("products")}
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="d-flex justify-content-between">
                  <Row className="d-flex flex-column gap-3 col-6">
                    <Input
                      name="categoryName"
                      control={control}
                      errors={errors}
                      label="category_name"
                      inputProps={{
                        disabled: true,
                      }}
                    />
                    {product?.names
                      ?.filter(
                        (item) =>
                          item.languageCode !== "en" &&
                          item.languageCode !== "uz-Cyrl-QQ"
                      )
                      .map((item) => (
                        <Input
                          key={item.languageCode}
                          name={`name${item.languageCode}`}
                          control={control}
                          errors={errors}
                          label={`name_${item.languageCode}`}
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      ))}
                    {product?.descriptions
                      ?.filter(
                        (item) =>
                          item.languageCode !== "en" &&
                          item.languageCode !== "uz-Cyrl-QQ"
                      )
                      .map((item) => (
                        <Input
                          key={item.languageCode}
                          name={`description${item.languageCode}`}
                          control={control}
                          errors={errors}
                          label={`description_${item.languageCode}`}
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      ))}
                    {product?.variations[0]?.prices?.map((item) => (
                      <Input
                        key={item.id}
                        name={`price_${item.type}`}
                        control={control}
                        errors={errors}
                        label={`${item.type}`}
                        inputProps={{
                          disabled: true,
                        }}
                      />
                    ))}
                    {product?.variations[0]?.files?.map((item) => (
                      <div
                        className="w-50 border form-control"
                        style={{ marginLeft: "0.8rem" }}
                        id="img2"
                        key={item.languageCode}
                      >
                        <img
                          src={item.url}
                          className="img-fluid w-100"
                          alt="Responsive"
                        />
                      </div>
                    ))}
                  </Row>
                  <Row className="d-flex flex-column gap-3 col-6">
                    {product?.variations[0]?.attributeValues
                      ?.filter((item) => item.attribute.isRequired === true)
                      .map((item) => (
                        <Input
                          key={item.id}
                          name={`value_${item.attribute?.name}`}
                          control={control}
                          errors={errors}
                          label={`${item.attribute?.name}`}
                          inputProps={{
                            disabled: true,
                          }}
                        />
                      ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ProductView;
