import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getReserveByIdQuery } from "../../../queries";
import { Card, CardBody, CardHeader } from "reactstrap";
import { prettify } from "../../../helpers/price-formater";
import { useTranslation } from "react-i18next";

const ReserveSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = useQuery({
    ...getReserveByIdQuery(id),
  });

  return (
    <div className="page-content">
      <div className="d-flex align-items-start gap-3 mb-2">
        <i
          className="ri-arrow-left-line fs-3 cursor-pointer"
          onClick={() => navigate(-1)}
        ></i>
        <h2>
          {t("reserve")} № {data?.id}
        </h2>
      </div>
      {data?.subOrders?.map((item, i) => (
        <Card key={i} style={{ maxWidth: "50%" }}>
          <CardHeader>{item?.id}</CardHeader>
          <CardBody className="d-flex gap-5 flex-column">
            {item?.items?.map((child) => (
              <div key={child} className="d-flex gap-5 justify-content-between">
                <div className="d-flex gap-3 align-items-start ">
                  <img
                    style={{
                      maxWidth: "100px",
                      width: "100px",
                      maxHeight: "100px",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    src={child.variation?.files?.[0]?.url}
                    alt={child.variation?.files?.[0]?.id}
                  />
                  <span style={{ width: "300px" }}>
                    {child.variation?.product.name}
                  </span>
                </div>
                <div>
                  {prettify(
                    child?.variation?.prices?.find(
                      (item) => item.type === "Price"
                    )?.value + " so'm"
                  )}
                </div>
                <div>{child?.count}</div>
              </div>
            ))}
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

export default ReserveSingle;
