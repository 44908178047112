import { Controller } from "react-hook-form";
import { Label, Input as InputB } from "reactstrap";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";

export const DateInput = ({
  name,
  control,
  errors,
  label,
  inputProps = {
    type: "text",
  },
  leftElement,
  rightElement,
  isInteger,
  maxLength,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {label && (
        <Label htmlFor="email" className="form-label" for={name}>
          {t(label)}
        </Label>
      )}
      {control && (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Flatpickr
              type="date"
              className="form-control"
              options={{
                dateFormat: "Y.m.d",
              }}
              value={field.value}
              {...inputProps}
              {...field}
              onChange={(date) => field.onChange(date[0])}
            />
          )}
        />
      )}
      {errors?.[name] && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {t(errors[name]?.message)}
        </div>
      )}
    </div>
  );
};
